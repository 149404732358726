<template>
  <v-data-table
    :headers="headers"
    :items="lessonTimeAttendances"
    disable-pagination
    hide-default-footer
    :loading="loading"
    :footer-props="{
      showFirstLastPage: true,
      itemsPerPageOptions: [10, 50, 100, 250],
      firstIcon: 'mdi-arrow-collapse-left',
      lastIcon: 'mdi-arrow-collapse-right'
    }"
    mobile-breakpoint="0"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <h4>
          {{ lessonTimeAttendances.length ? 'Yoklama Bilgisi' : 'Yoklama Bilgisi Bulunamadı' }}
        </h4>
      </v-toolbar>
    </template>
    <template v-slot:[`item.LessonTime.StartDate`]="{ item }">
      {{ item.LessonTime.StartDate | dateFormat }} / {{ item.LessonTime.StartDate | time }} -
      {{ item.LessonTime.EndDate | time }}
    </template>
  </v-data-table>
</template>

<script>
import moment from 'moment'
import ApiService from '@/core/services/api.service'

export default {
  data() {
    return {
      headers: [
        { text: 'Ders', value: 'Course.Name', sortable: true },
        { text: 'Yoklama Durumu', value: 'AttendanceStatusName', sortable: true },
        { text: 'Tarih', value: 'LessonTime.StartDate', sortable: true }
      ],
      lessonTimeAttendances: [],
      loading: false,
    }
  },
  filters: {
    time: function (date) {
      return moment(date).locale('tr').format('LT')
    },
    dateFormat(StartDate) {
      var startDate = moment(StartDate).locale('tr').format('LL')
      return startDate
    }
  },
  mounted() {
    this.getDataFromApi()
  },
  methods: {
    getDataFromApi() {
      this.loading = true
      ApiService.setHeader()
      ApiService.post('api/StudentAttendance', {
        sortBy: ['Id'],
        descending: [true],
        rowsPerPage: -1,
        page: 1
      })
        .then((data) => {
          this.lessonTimeAttendances = data.data.Results
          this.loading = false
        })
        .catch(({ response }) => {
          ApiService.showError(response)
        })
    }
  }
}
</script>
