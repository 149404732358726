var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-data-table", {
    attrs: {
      headers: _vm.headers,
      items: _vm.lessonTimeAttendances,
      "disable-pagination": "",
      "hide-default-footer": "",
      loading: _vm.loading,
      "footer-props": {
        showFirstLastPage: true,
        itemsPerPageOptions: [10, 50, 100, 250],
        firstIcon: "mdi-arrow-collapse-left",
        lastIcon: "mdi-arrow-collapse-right",
      },
      "mobile-breakpoint": "0",
    },
    scopedSlots: _vm._u(
      [
        {
          key: "top",
          fn: function () {
            return [
              _c("v-toolbar", { attrs: { flat: "" } }, [
                _c("h4", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.lessonTimeAttendances.length
                          ? "Yoklama Bilgisi"
                          : "Yoklama Bilgisi Bulunamadı"
                      ) +
                      " "
                  ),
                ]),
              ]),
            ]
          },
          proxy: true,
        },
        {
          key: `item.LessonTime.StartDate`,
          fn: function ({ item }) {
            return [
              _vm._v(
                " " +
                  _vm._s(_vm._f("dateFormat")(item.LessonTime.StartDate)) +
                  " / " +
                  _vm._s(_vm._f("time")(item.LessonTime.StartDate)) +
                  " - " +
                  _vm._s(_vm._f("time")(item.LessonTime.EndDate)) +
                  " "
              ),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }